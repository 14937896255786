<script>
import router from "@/router";
import CampoTexto from '../../components/campos_dados/campo_texto';
import CampoSelect from '../../components/campos_dados/campo_select';
import TiposDeCampo from '../../components/campos_dados/tipos_de_campo';
import { baseApiUrl, showSuccess, showError } from '@/global';
import axios from "axios";
import moment from "moment";

const camposBases = {
  codIdentificacao: new CampoTexto({
    nome: 'cod_identificacao',
    label: 'Código de identificação',
    icone: 'fas fa-file-alt',
    valor: '',
    regras: [],
  }),
  descricao: new CampoTexto({
    nome: 'descricao',
    label: 'Descrição',
    icone: 'fas fa-stream',
    valor: '',
    regras: [],
  }),
  modal: new CampoSelect({
    nome: 'modal',
    label: 'Modal',
    icone: 'fas fa-th-list',
    valor: '',
    itens: [],
    regras: [],
  }),
};

const camposDadosRestantes = {
  limiteCubagem: new CampoTexto({
    nome: 'limite_cubagem',
    label: 'Limite de cubagem',
    icone: 'fas fa-cubes',
    valor: '',
    regras: [],
    numerico: true,
  }),
  marca: new CampoTexto({
    nome: 'marca',
    label: 'Marca',
    icone: 'fas fa-copyright',
    valor: '',
    regras: [],
  }),
  grupo: new CampoTexto({
    nome: 'grupo',
    label: 'Grupo',
    icone: 'fas fa-layer-group',
    valor: '',
    regras: [],
  }),
  totalAgregados: new CampoTexto({
    nome: 'total_agregados',
    label: 'Total agregados',
    icone: 'fas fa-poll-h',
    valor: '',
    regras: [],
    numerico: true,
  }),
  doca: new CampoSelect({
    nome: 'doca',
    label: 'Doca',
    icone: 'fas fa-folder',
    valor: '',
    itens: [],
    regras: [],
  }),
  embalamento: new CampoSelect({
    nome: 'embalamento',
    label: 'Habilitar regra de embalamento',
    icone: 'fas fa-scroll',
    valor: 'Não',
    itens: ['Sim', 'Não'],
    explicacao: 'O sistema encaminhará as medidas do produto para procura de caixa pré-cadastrada.',
    regras: [],
  }),
};

class CamposDimensoes {
  comprimento = new CampoTexto({
    nome: 'comprimento',
    label: 'Comprimento (m)',
    icone: 'fas fa-ruler-horizontal',
    valor: '',
    regras: [
      value => {
        value = Number(value);

        if (value <= 0)
          return 'Informe o comprimento.';

        return true;
      }
    ],
    numerico: true,
    aoMudar: () => {
      this.cubagem.valor = this.altura.valor * this.comprimento.valor * this.largura.valor;
    }
  });
  largura = new CampoTexto({
    nome: 'largura',
    label: 'Largura (m)',
    icone: 'fas fa-ruler',
    valor: '',
    regras: [
      value => {
        value = Number(value);

        if (value <= 0)
          return 'Informe a largura.';

        return true;
      }
    ],
    numerico: true,
    aoMudar: () => {
      this.cubagem.valor = this.altura.valor * this.comprimento.valor * this.largura.valor;
    }
  });
  altura = new CampoTexto({
    nome: 'altura',
    label: 'Altura (m)',
    icone: 'fas fa-ruler-vertical',
    valor: '',
    regras: [
      value => {
        value = Number(value);

        if (value <= 0)
          return 'Informe a altura.';

        return true;
      }
    ],
    numerico: true,
    aoMudar: () => {
      this.cubagem.valor = this.altura.valor * this.comprimento.valor * this.largura.valor;
    }
  });
  cubagem = new CampoTexto({
    nome: 'cubagem',
    label: 'Cubagem (m³)',
    icone: 'fas fa-cube',
    valor: 0,
    regras: [
      value => {
        value = Number(value);

        if (value <= 0)
          return 'Informe a cubagem.';

        return true;
      }
    ],
    bloqueado: true,
    numerico: true,
  });
  peso = new CampoTexto({
    nome: 'peso',
    label: 'Peso (kg)',
    icone: 'fas fa-weight-hanging',
    valor: '',
    regras: [
      value => {
        value = Number(value);

        if (value <= 0)
          return 'Informe o  peso.';

        return true;
      }
    ],
    numerico: true,
  });
  volume = new CampoTexto({
    nome: 'volume',
    label: 'Volume (UN)',
    icone: 'fas fa-fill',
    valor: '',
    regras: [
      value => {
        value = Number(value);

        if (value <= 0)
          return 'Informe o volume.';

        return true;
      }
    ],
    numerico: true,
  });
};

const camposDimensoes = new CamposDimensoes();

const estadoAgregados = {
  /** @type {CamposDimensoes[]} */
  estadoAtual: [
  ],

  qtdAgregados: 0,

  adicionarAgregado() {
    this.qtdAgregados++;
    this.estadoAtual.push(new CamposDimensoes());
  },

  removerAgregado(index) {
    this.qtdAgregados--;
    this.estadoAtual.splice(index, 1);
  },
};

const estadoVolumesExtras = {
  /** @type {CamposDimensoes[]} */
  estadoAtual: [
  ],

  qtdVolumesExtras: 0,

  adicionarVolumesExtras() {
    this.qtdVolumesExtras++;
    this.estadoAtual.push(new CamposDimensoes());
  },

  removerVolumesExtras(index) {
    this.qtdVolumesExtras--;
    this.estadoAtual.splice(index, 1);
  },
}

const camposAdicional = {
  prazo: new CampoTexto({
    nome: 'prazo',
    label: 'Prazo',
    icone: 'fas fa-calendar',
    valor: '',
    regras: [],
  }),
  valor: new CampoTexto({
    nome: 'valor',
    label: 'Valor',
    icone: 'fas fa-coins',
    valor: '',
    regras: [],
    numerico: true,
  }),
  tipo: new CampoSelect({
    nome: 'tipo',
    label: 'Tipo',
    icone: 'fas fa-window-restore',
    valor: '',
    itens: ['Valor', 'Porcentagem'],
    regras: [],
  }),
  calculo: new CampoSelect({
    nome: 'calculo',
    label: 'Cálculo',
    icone: 'fas fa-calculator',
    valor: '',
    itens: ['Frete', 'Produto'],
    regras: [],
  }),
};

const camposValoresVenda = {
  valor1: new CampoTexto({
    nome: 'valor1',
    label: 'Madeira Madeira',
    icone: 'fas fa-dollar-sign',
    valor: '',
    regras: [],
    numerico: true,
  }),
  valor2: new CampoTexto({
    nome: 'valor2',
    label: 'Netshoes',
    icone: 'fas fa-dollar-sign',
    valor: '',
    regras: [],
    numerico: true,
  }),
  valor3: new CampoTexto({
    nome: 'valor3',
    label: 'Leroy Merlin',
    icone: 'fas fa-dollar-sign',
    valor: '',
    regras: [],
    numerico: true,
  }),
  valor4: new CampoTexto({
    nome: 'valor4',
    label: 'Via Varejo',
    icone: 'fas fa-dollar-sign',
    valor: '',
    regras: [],
    numerico: true,
  }),
  valor5: new CampoTexto({
    nome: 'valor5',
    label: 'Convertize',
    icone: 'fas fa-dollar-sign',
    valor: '',
    regras: [],
    numerico: true,
  }),
  valor6: new CampoTexto({
    nome: 'valor6',
    label: 'Carrefour',
    icone: 'fas fa-dollar-sign',
    valor: '',
    regras: [],
    numerico: true,
  }),
  valor7: new CampoTexto({
    nome: 'valor7',
    label: 'Conecta',
    icone: 'fas fa-dollar-sign',
    valor: '',
    regras: [],
    numerico: true,
  }),
  valor8: new CampoTexto({
    nome: 'valor8',
    label: 'Renner',
    icone: 'fas fa-dollar-sign',
    valor: '',
    regras: [],
    numerico: true,
  }),
  valor9: new CampoTexto({
    nome: 'valor9',
    label: 'Shopee',
    icone: 'fas fa-dollar-sign',
    valor: '',
    regras: [],
    numerico: true,
  }),
};

async function gravarDados() {
  this.$refs.form.validate();
}

function fecharPagina(){
  router.push({
    path: '/cadastro-produtos',
  });
}

export default {
  name: 'EditarProduto',

  data: () => ({
    produto: null,

    TiposDeCampo,
    camposBases,
    camposDimensoes,
    camposAdicional,
    camposValoresVenda,
    camposDadosRestantes,
    estadoAgregados,
    estadoVolumesExtras,
  }),

  created() {
    this.produto = this.$route.params.produto;
    this.limparValores();
    this.carregarEdicao();
  },

  methods: {
    gravarDados,
    fecharPagina,

    carregarEdicao(){
      this.estadoAgregados.estadoAtual = [];
      this.estadoVolumesExtras.estadoAtual = [];
      if(this.produto.preco_venda != null){
        this.produto.preco_venda = this.produto.preco_venda.replace(/'/g, '"');
      }
      let valor_venda = JSON.parse(this.produto.preco_venda);
      let agregados = JSON.parse(this.produto.agregados);
      let vol_extra = JSON.parse(this.produto.volume_extra);

      this.camposBases.codIdentificacao.valor = this.produto.idenf;
      this.camposBases.descricao.valor = this.produto.descricao;
      this.camposBases.modal.valor = this.produto.modal;

      this.camposDadosRestantes.limiteCubagem.valor = this.produto.limitacao_cubagem;
      this.camposDadosRestantes.marca.valor = this.produto.marca;
      this.camposDadosRestantes.grupo.valor = this.produto.grupo;
      this.camposDadosRestantes.totalAgregados.valor = this.produto.total_agregados;
      this.camposDadosRestantes.doca.valor = this.produto.doca_id;

      if(this.produto.embalamento == 1){
        this.camposDadosRestantes.embalamento.valor = "Sim";
      }else{
        this.camposDadosRestantes.embalamento.valor = "Não";
      }

      this.camposDimensoes.comprimento.valor = this.produto.comprimento;
      this.camposDimensoes.largura.valor = this.produto.largura;
      this.camposDimensoes.altura.valor = this.produto.altura;
      this.camposDimensoes.cubagem.valor = this.produto.cubagem;
      this.camposDimensoes.peso.valor = this.produto.peso;
      this.camposDimensoes.volume.valor = this.produto.volume;

      if(agregados != null){
        for(let cont = 0; cont < agregados.length; cont++){
          this.estadoAgregados.adicionarAgregado();
          this.estadoAgregados.estadoAtual[cont].comprimento.valor = agregados[cont].comprimento;
          this.estadoAgregados.estadoAtual[cont].altura.valor = agregados[cont].altura;
          this.estadoAgregados.estadoAtual[cont].cubagem.valor = agregados[cont].cubagem;
          this.estadoAgregados.estadoAtual[cont].largura.valor = agregados[cont].largura;
          this.estadoAgregados.estadoAtual[cont].peso.valor = agregados[cont].peso;
          this.estadoAgregados.estadoAtual[cont].volume.valor = agregados[cont].volume;
        }
      }

      if(vol_extra != null){
        for(let cont = 0; cont < vol_extra.length; cont++){
          this.estadoVolumesExtras.adicionarVolumesExtras();
          this.estadoVolumesExtras.estadoAtual[cont].comprimento.valor = vol_extra[cont].comprimento;
          this.estadoVolumesExtras.estadoAtual[cont].altura.valor = vol_extra[cont].altura;
          this.estadoVolumesExtras.estadoAtual[cont].cubagem.valor = vol_extra[cont].cubagem;
          this.estadoVolumesExtras.estadoAtual[cont].largura.valor = vol_extra[cont].largura;
          this.estadoVolumesExtras.estadoAtual[cont].peso.valor = vol_extra[cont].peso;
          this.estadoVolumesExtras.estadoAtual[cont].volume.valor = vol_extra[cont].volume;
        }
      }

      this.camposAdicional.prazo.valor = this.produto.prazo;
      this.camposAdicional.valor.valor = this.produto.valor_adc;
      
      if(this.produto.tipo_adc == "V")
        this.camposAdicional.tipo.valor = "Valor";
      else if(this.produto.tipo_adc == "P")
        this.camposAdicional.tipo.valor = "Porcentagem";

      if(this.produto.calc_adc == "F")
        this.camposAdicional.calculo.valor = "Frete";
      else if(this.produto.calc_adc == "P")
        this.camposAdicional.calculo.valor = "Produto";

      if(valor_venda != null){
        this.camposValoresVenda.valor1.valor = valor_venda.Madeira_Madeira;
        this.camposValoresVenda.valor2.valor = valor_venda.Netshoes;
        this.camposValoresVenda.valor3.valor = valor_venda.Leroy_Merlin;
        this.camposValoresVenda.valor4.valor = valor_venda.Via_Varejo;
        this.camposValoresVenda.valor5.valor = valor_venda.Convertize;
        this.camposValoresVenda.valor6.valor = valor_venda.Carrefour;
        this.camposValoresVenda.valor7.valor = valor_venda.Conecta;
        this.camposValoresVenda.valor8.valor = valor_venda.Renner;
        this.camposValoresVenda.valor9.valor = valor_venda.Shopee;
      }
    },

    limparValores() {
      this.camposValoresVenda.valor1.valor = '';
      this.camposValoresVenda.valor2.valor = '';
      this.camposValoresVenda.valor3.valor = '';
      this.camposValoresVenda.valor4.valor = '';
      this.camposValoresVenda.valor5.valor = '';
      this.camposValoresVenda.valor6.valor = '';
      this.camposValoresVenda.valor7.valor = '';
      this.camposValoresVenda.valor8.valor = '';
      this.camposValoresVenda.valor9.valor = '';
    },

    async saveEdit(){
      let url = `${baseApiUrl}/produtos-atualizar?idprodutos=${this.produto.idprodutos}`;
      let date = moment(new Date()).format("YYYY-MM-DD");
      let agregados = [];
      let vol_extra = [];
      let tipo_adc = "";
      let calc_adc = "";
      let embalamentoFinal = null;

      if(this.camposAdicional.tipo.valor == 'Valor'){
        tipo_adc = "V";
      }else if(this.camposAdicional.tipo.valor == 'Porcentagem'){
        tipo_adc = "P";
      }
      

      if(this.camposAdicional.calculo.valor == 'Frete'){
        calc_adc = "F";
      }else if(this.camposAdicional.calculo.valor == 'Produto'){
        calc_adc = "P";
      }

      if(this.estadoAgregados.estadoAtual.length != 0){
        for(let cont = 0; cont < this.estadoAgregados.estadoAtual.length; cont++){
          agregados.push({
            "altura": this.estadoAgregados.estadoAtual[cont].altura.valor,
            "comprimento": this.estadoAgregados.estadoAtual[cont].comprimento.valor,
            "cubagem": this.estadoAgregados.estadoAtual[cont].cubagem.valor,
            "largura": this.estadoAgregados.estadoAtual[cont].largura.valor,
            "peso": this.estadoAgregados.estadoAtual[cont].peso.valor,
            "volume": this.estadoAgregados.estadoAtual[cont].volume.valor,
          });
        }
      }

      if(this.estadoVolumesExtras.estadoAtual.length != 0){
        for(let cont = 0; cont < this.estadoVolumesExtras.estadoAtual.length; cont++){
          vol_extra.push({
            "altura": this.estadoVolumesExtras.estadoAtual[cont].altura.valor,
            "comprimento": this.estadoVolumesExtras.estadoAtual[cont].comprimento.valor,
            "cubagem": this.estadoVolumesExtras.estadoAtual[cont].cubagem.valor,
            "largura": this.estadoVolumesExtras.estadoAtual[cont].largura.valor,
            "peso": this.estadoVolumesExtras.estadoAtual[cont].peso.valor,
            "volume": this.estadoVolumesExtras.estadoAtual[cont].volume.valor,
          })
        }
      }

    if(this.camposDadosRestantes.embalamento.valor == "Não")
      embalamentoFinal = 0;
    else
      embalamentoFinal = 1;

    this.$swal({
      title: 'Confirmação',
      text: `Você confirma as alterações feitas no produto ${this.produto.idenf}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      confirmButtonColor: '#4BB543',
      cancelButtonColor: '#d33',
      allowOutsideClick: false,
    }).then(async (res) => {
      if(res.isConfirmed){
        await axios
          .put(url, {
            "cod_identificacao": this.camposBases.codIdentificacao.valor,
            "descricao": this.camposBases.descricao.valor,
            "limite_cubagem": this.camposDadosRestantes.limiteCubagem.valor,
            "marca": this.camposDadosRestantes.marca.valor,
            "grupo": this.camposDadosRestantes.grupo.valor,
            "doca_id": this.camposDadosRestantes.doca.valor,
            "total_agregados": this.camposDadosRestantes.totalAgregados.valor,
            "embalamento": embalamentoFinal,
            "comprimento": this.camposDimensoes.comprimento.valor,
            "largura": this.camposDimensoes.largura.valor,
            "altura": this.camposDimensoes.altura.valor,
            "cubagem": this.camposDimensoes.cubagem.valor,
            "peso": this.camposDimensoes.peso.valor,
            "volume": this.camposDimensoes.volume.valor,
            "prazo": this.camposAdicional.prazo.valor,
            "valor": this.camposAdicional.valor.valor,
            "tipo": tipo_adc,
            "calculo": calc_adc,
            "preco_venda": {
              'valor1': this.camposValoresVenda.valor1.valor,
              'valor2': this.camposValoresVenda.valor2.valor,
              'valor3': this.camposValoresVenda.valor3.valor,
              'valor4': this.camposValoresVenda.valor4.valor,
              'valor5': this.camposValoresVenda.valor5.valor,
              'valor6': this.camposValoresVenda.valor6.valor,
              'valor7': this.camposValoresVenda.valor7.valor,
              'valor8': this.camposValoresVenda.valor8.valor,
              // 'Shopee': this.camposValoresVenda.valor9.valor,
            },
            "agregados": agregados,
            "volume_extra": vol_extra,
            "criado_em": date
          }).then((result) => {
            showSuccess(result.data.dados);
          }).catch(() => {
            showError("Não foi possível editar o produto");
          })
      }
    })
    }
  }
}

</script>

<template>
  <article>
    <!-- Título. -->
    <v-toolbar flat>
      <v-icon size="100">
        fas fa-edit
      </v-icon>
      <v-toolbar-title class="pl-4" style="font-size: 24px">
        Editar produto {{ produto.idenf }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon @click="fecharPagina()">
        <v-icon>fas fa-times</v-icon>
      </v-app-bar-nav-icon>
    </v-toolbar>

    <v-form ref="form" @submit.prevent="gravarDados" style="margin: 5px">
      <!-- Campos bases. -->
      <v-card outlined>
        <v-card-title class="subtitle-1">Produto</v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" sm="6" md="3" v-for="campo in camposBases" :key="campo.nome">
              <v-text-field outlined dense v-if="campo.tipoDeCampo == TiposDeCampo.texto" @click="campo.aoClicar"
                :disabled="campo.bloqueado" :type="campo.numerico ? 'number' : 'text'" :append-icon="campo.icone"
                :name="campo.nome" :label="campo.label" autocomplete="off" v-model="campo.valor" :rules="campo.regras"
                color="#018656" @change="campo.aoMudar">
              </v-text-field>

              <v-select outlined dense v-else-if="campo.tipoDeCampo == TiposDeCampo.select" @click="campo.aoClicar"
                :disabled="campo.bloqueado" :append-icon="campo.icone" :name="campo.nome" :label="campo.label"
                autocomplete="off" v-model="campo.valor" :rules="campo.regras" :items="campo.itens" color="#018656"
                @change="campo.aoMudar">
              </v-select>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-btn @click="saveEdit()" type="submit" color="#018656" style="color: #FFFFFF; width: 100%; height: 38px;">
                <v-icon small dense class="mx-2">fas fa-pencil-alt</v-icon>
                Confirmar Edição
              </v-btn>
            </v-col>
          </v-row>

          <!-- Demais campos. -->
          <v-row dense align="center">
            <v-col cols="12" sm="6" md="3" v-for="campo in camposDadosRestantes" :key="campo.nome">
              <v-text-field dense outlined v-if="campo.tipoDeCampo == TiposDeCampo.texto" @click="campo.aoClicar"
                :disabled="campo.bloqueado" :type="campo.numerico ? 'number' : 'text'" :append-icon="campo.icone"
                :name="campo.nome" :label="campo.label" autocomplete="off" v-model="campo.valor" :rules="campo.regras"
                color="#018656" @change="campo.aoMudar">
              </v-text-field>

              <abbr v-else-if="campo.tipoDeCampo == TiposDeCampo.select" :title="campo.explicacao"
                style="text-decoration: none;">
                <v-select dense outlined @click="campo.aoClicar" :disabled="campo.bloqueado" :append-icon="campo.icone"
                  :name="campo.nome" :label="campo.label" autocomplete="off" v-model="campo.valor" :rules="campo.regras"
                  :items="campo.itens" color="#018656" @change="campo.aoMudar">
                </v-select>
              </abbr>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <!-- Campos de dimensões do produto. -->
      <v-card outlined style="margin-top: 10px;">
        <v-card-title class="subtitle-1">Dimensões</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6" md="3" v-for="campo in camposDimensoes" :key="campo.nome">
              <v-text-field dense v-if="campo.tipoDeCampo == TiposDeCampo.texto" @click="campo.aoClicar"
                :disabled="campo.bloqueado" :type="campo.numerico ? 'number' : 'text'" :append-icon="campo.icone"
                :name="campo.nome" :label="campo.label" autocomplete="off" v-model="campo.valor" :rules="campo.regras"
                color="#018656" @change="campo.aoMudar">
              </v-text-field>

              <v-select dense v-else-if="campo.tipoDeCampo == TiposDeCampo.select" @click="campo.aoClicar"
                :disabled="campo.bloqueado" :append-icon="campo.icone" :name="campo.nome" :label="campo.label"
                autocomplete="off" v-model="campo.valor" :rules="campo.regras" :items="campo.itens" color="#018656"
                @change="campo.aoMudar">
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <!-- Adicional. -->
      <v-card outlined style="margin-top: 10px;">
        <v-card-title class="subtitle-1">Adicional</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6" md="3" v-for="campo in camposAdicional" :key="campo.nome">
              <v-text-field dense v-if="campo.tipoDeCampo == TiposDeCampo.texto" @click="campo.aoClicar"
                :disabled="campo.bloqueado" :type="campo.numerico ? 'number' : 'text'" :append-icon="campo.icone"
                :name="campo.nome" :label="campo.label" autocomplete="off" v-model="campo.valor" :rules="campo.regras"
                color="#018656" @change="campo.aoMudar">
              </v-text-field>

              <v-select dense v-else-if="campo.tipoDeCampo == TiposDeCampo.select" @click="campo.aoClicar"
                :disabled="campo.bloqueado" :append-icon="campo.icone" :name="campo.nome" :label="campo.label"
                autocomplete="off" v-model="campo.valor" :rules="campo.regras" :items="campo.itens" color="#018656"
                @change="campo.aoMudar">
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <!-- Valor de venda. -->
      <v-card outlined style="margin-top: 10px;">
        <v-card-title class="subtitle-1">Valor de venda (R$)</v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="6" sm="2" md="2" v-for="campo in camposValoresVenda" :key="campo.nome">
              <p>{{ campo.label }}</p>
              <v-text-field outlined dense v-if="campo.tipoDeCampo == TiposDeCampo.texto" @click="campo.aoClicar"
                :disabled="campo.bloqueado" type="text" :append-icon="campo.icone"
                :name="campo.nome" autocomplete="off" v-model="campo.valor" :rules="campo.regras" color="#018656"
                @change="campo.aoMudar">
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <!-- Agregados. -->
      <v-card outlined style="margin-top: 10px;">
        <v-card-title class="subtitle-1">Agregados</v-card-title>
        <v-card-text>
          <!-- Adicionar agregado. -->
          <v-row dense style="margin-bottom: 5px;">
            <v-col cols="12">
              <v-btn outlined type="button" @click="
                estadoAgregados.adicionarAgregado()" color="#FF5252" style="color: #018656; width: 100%;">
                <v-icon dense class="mx-2">fas fa-plus</v-icon>
                ADICIONAR AGREGADO
              </v-btn>
            </v-col>
          </v-row>

          <!-- Agregado. -->
          <v-card outlined v-for="(agregado, index) in estadoAgregados.estadoAtual" :key="index"
            style="margin-bottom: 15px;">
            <v-card-text>
              <v-row dense style="margin-top: 5px;">
                <v-col cols=" 12" sm="6" md="3" v-for="campo in agregado" :key="campo.nome">
                  <v-text-field dense outlined v-if="campo.tipoDeCampo == TiposDeCampo.texto" @click="campo.aoClicar"
                    :disabled="campo.bloqueado" :type="campo.numerico ? 'number' : 'text'" :append-icon="campo.icone"
                    :name="campo.nome" :label="campo.label" autocomplete="off" v-model="campo.valor" :rules="campo.regras"
                    color="#018656" @change="campo.aoMudar">
                  </v-text-field>
                </v-col>
              </v-row>

              <!-- Excluir agregado. -->
              <v-row dense v-if="estadoAgregados.qtdAgregados > 0" style="margin-bottom: 5px;">
                <v-col cols="12" sm="6" md="3">
                  <v-btn outlined type="button" @click="
                    estadoAgregados.removerAgregado(index)" color="#FF5252" style="color: #FF5252; width: 100%;">
                    <v-icon dense class="mx-2">fas fa-trash-alt</v-icon>
                    EXCLUIR AGREGADO
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>


      <!-- Volumes extras. -->
      <v-card outlined style="margin-top: 10px;">
        <v-card-title class="subtitle-1">Volumes extras</v-card-title>
        <v-card-text>
          <!-- Adicionar volume extra. -->
          <v-row dense style="margin-bottom: 5px;">
            <v-col cols="12">
              <v-btn outlined type="button" @click="
                estadoVolumesExtras.adicionarVolumesExtras()" color="#FF5252" style="color: #018656; width: 100%;">
                <v-icon dense class="mx-2">fas fa-plus</v-icon>
                ADICIONAR VOLUME EXTRA
              </v-btn>
            </v-col>
          </v-row>

          <!-- Volume extra. -->
          <v-card outlined v-for="(volumesExtras, index) in estadoVolumesExtras.estadoAtual" :key="index"
            style="margin-bottom: 15px;">
            <v-card-text>
              <v-row dense style="margin-top: 5px;">
                <v-col cols=" 12" sm="6" md="3" v-for="campo in volumesExtras" :key="campo.nome">
                  <v-text-field dense outlined v-if="campo.tipoDeCampo == TiposDeCampo.texto" @click="campo.aoClicar"
                    :disabled="campo.bloqueado" :type="campo.numerico ? 'number' : 'text'" :append-icon="campo.icone"
                    :name="campo.nome" :label="campo.label" autocomplete="off" v-model="campo.valor" :rules="campo.regras"
                    color="#018656" @change="campo.aoMudar">
                  </v-text-field>
                </v-col>
              </v-row>

              <!-- Excluir volume extra. -->
              <v-row dense v-if="estadoVolumesExtras.qtdVolumesExtras > 0" style="margin-bottom: 5px;">
                <v-col cols="12" sm="6" md="3">
                  <v-btn outlined type="button" @click="
                    estadoVolumesExtras.removerVolumesExtras(index)" color="#FF5252"
                    style="color: #FF5252; width: 100%;">
                    <v-icon dense class="mx-2">fas fa-trash-alt</v-icon>
                    EXCLUIR VOLUME EXTRA
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-form>
  </article>
</template>